<template>
  <div>
    <div style="height: 30px;line-height: 30px;padding-left: 15px;font-size: 15px">
      Welcome！{{ clientId }}<a style="margin-left: 15px" @click="loginOut">Exit</a>
    </div>
    <iframe src="https://office.qiaooomo.net:9000/"
            style="border: none;" :style="{width:(windowWidth)+'px',height:(windowHeight-40)+'px'}"></iframe>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: 'Home',
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      clientId: '',
      checkInstance: null,
    }
  },
  methods: {
    loginOut() {
      if (this.$data.checkInstance) {
        clearInterval(this.$data.checkInstance)
      }
      this.$router.push("/login");
    },
    checkToken() {
      let cookie = Cookies.get('doorToken');
      if (cookie != null) {
        let userToken = JSON.parse(cookie).token;
        console.log("check token");
        axios.post("/api/login/checkUserToken", {userToken}, {
          headers: {"Content-Type": "application/json"}
        }).then((res) => {
          if (res.data.code !== 0) {
            this.loginOut();
          }
        })
      } else {
        this.loginOut();
      }
    }
  },
  created() {
    let _this = this;
    window.onresize = () => {
      _this.$data.windowHeight = window.innerHeight;
      _this.$data.windowWidth = window.innerWidth;
    }
    let cookie = Cookies.get('doorToken');
    if (cookie != null) {
      this.$data.clientId = JSON.parse(cookie).clientId;
    }
    this.checkToken();
    this.$data.checkInstance = setInterval(()=>{
      this.checkToken();
    },30000);
  }
}
</script>

<style scoped>

</style>
