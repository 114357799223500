import { createApp} from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'
// const { VUE_APP_BACKGROUND, VUE_APP_TITLE = '' } = process.env
const app = createApp(App)
// axios.defaults.baseURL=VUE_APP_BACKGROUND;
app.use(store)
  .use(router)
  .use(ViewUIPlus)
  .mount('#app')
