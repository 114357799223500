<template>
  <div :style="{width:(width-10)+'px'}">
    <Row :gutter="15" align="middle" :style="{height:(height-15)+'px'}">
      <Col :xs="{span:22,offset:1}" :sm="{span:20,offset:2}" :md="{span:18,offset:3}" :lg="{span:16,offset:4}"
           :xl="{span:14,offset:5}" :xxl="{span:12,offset:6}">
        <Card dis-hover>
          <Form>
            <FormItem label="username">
              <Input type="text" placeholder="username" v-model="loginForm.accessKey"/>
            </FormItem>
            <FormItem label="password">
              <Input type="number" placeholder="password" v-model="loginForm.accessToken"/>
            </FormItem>
            <FormItem>
              <Button long type="primary" ghost @click="login">Login</Button>
            </FormItem>
          </Form>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import {Button, Card, Col, Form, FormItem, Input} from "view-ui-plus";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: 'Login',
  components: {Button, Col, Card, Input, FormItem, Form},
  data() {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
      loginForm: {
        accessKey: "",
        accessToken: null
      }
    }
  },
  methods: {
    login() {
      axios.post("/api/login/signToken", this.$data.loginForm, {
        headers: {"Content-Type": "application/json"}
      }).then((res) => {
        if (res.data.code === 0) {
          Cookies.set("doorToken", JSON.stringify(res.data.data));
          this.$router.push("/");
        } else {
          this.$Modal.error({
            title: "登录失败",
            content: res.data.errorInfo + " (" + res.data.code + ")"
          });
        }
      })
    }
  },
  created() {
    Cookies.remove('doorToken')
  }
}
</script>

<style scoped>

</style>
